import moment from 'moment';
import * as Yup from 'yup';
import { getCookie } from '../../../services/helpers/cookies';
import getLatinise from '../../../services/helpers/latinise';

const _ = require('lodash');

function arrangeFormFields(fields, data, catalogs) {
    let response = [...fields];
    if (response && response.length > 0) {
        response.map((field, index) => {
            if(field.type !== "customElement"){
                if (data) {
                    field.value = getValue(((field.valuePath) ? field.valuePath : field.name), data);
                    if (!field.noAddOneDayToDate && (field.value && moment(field.value).isValid()) && (field.type && field.type === "date")) {
                        field.value = moment(field.value).add(1, "days").format('YYYY-MM-DD');
                    }
                }
                if (field.hasOwnProperty('optionsCtlName') && (catalogs && catalogs[field.optionsCtlName])) {
                    field.selectOptions = catalogs[field.optionsCtlName];
                }
            }
        });
    }
    return response;
}

function getValue(path, data) {
    let pathSplit = path.split(".");
    if (pathSplit.length > 1) {
        let dataNested = data[pathSplit[0]];
        let pathNested = pathSplit.slice(1).join('.');
        return getValue(pathNested, dataNested);
    } else {
        return (Array.isArray(data)) ? _.map(data, path) : _.get(data, path);
    }
}

function arrangeFormikInitialValues(fields) {
    if (fields && fields.length > 0) {
        return fields.reduce(
            (inputs, singleInput) => ({
                ...inputs,
                [singleInput.name]: (singleInput.value != null && singleInput.value != undefined && singleInput.value !== "undefined") ? singleInput.value : "",
            }),
            {}
        );
    } else {
        return null;
    }
}

function arrangeFormikSchema(fields) {
    let response = {};
    if (fields && fields.length > 0) {
        fields.map((field) => {
            //OK, por cada field, debemos validar si tiene alguna regla por aplicar de acuerdo al required, type y variant.
            let yupConfig = Yup.string();
            switch (field.type) {
                case "number":
                    yupConfig = Yup.number();
                    break;
                case "checkbox":
                    yupConfig = Yup.boolean();
                    break;
            }
            switch (field.variant) {
                case "email":
                    yupConfig = yupConfig.email('Correo electrónico no valido');
                    break;
                case "rfc":
                    yupConfig = yupConfig.matches(/^([A-ZÑ]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))([A-Z\d]{3})$/g, "RFC no valido");
                    break;
                case "curp":
                    yupConfig = yupConfig.matches(/^([A-Z&]|[a-z&]{1})([AEIOU]|[aeiou]{1})([A-Z&]|[a-z&]{1})([A-Z&]|[a-z&]{1})([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])([HM]|[hm]{1})([AS|as|BC|bc|BS|bs|CC|cc|CS|cs|CH|ch|CL|cl|CM|cm|DF|df|DG|dg|GT|gt|GR|gr|HG|hg|JC|jc|MC|mc|MN|mn|MS|ms|NT|nt|NL|nl|OC|oc|PL|pl|QT|qt|QR|qr|SP|sp|SL|sl|SR|sr|TC|tc|TS|ts|TL|tl|VZ|vz|YN|yn|ZS|zs|NE|ne]{2})([^A|a|E|e|I|i|O|o|U|u]{1})([^A|a|E|e|I|i|O|o|U|u]{1})([^A|a|E|e|I|i|O|o|U|u]{1})([0-9]{2})$/g, "CURP no valido");
                    break;
                case "slug":
                    yupConfig = yupConfig.matches(/^[a-z0-9]+(?:_[a-z0-9]+)*$/g, "Identificador no valido");
                    break;
            }
            if (field.required) {
                yupConfig = yupConfig.required('Este campo es requerido');
            }
            if (field.minLength) {
                yupConfig = yupConfig.min(field.minLength, 'La longitud mínima para este campo es de ' + field.minLength + ' caracteres');
            }
            if (field.maxLength) {
                yupConfig = yupConfig.max(field.maxLength, 'La longitud máxima  para este campo es de ' + field.maxLength + ' caracteres');
            }
            if (field.onlyLetters) {
                yupConfig = yupConfig.matches(/^[aA-zZ\s]+$/g, "Solo se permiten letras para este campo");
            }
            if (field.onlyNumbers) {
                yupConfig = yupConfig.matches(/^\d*(\.\d+)?$/, "Solo se permiten números para este campo");
            }
            if (field.length) {
                yupConfig = yupConfig.min(field.length);
            }
            if (field.number) {
                yupConfig = yupConfig.matches(/[0-9]/);
            }
            if (field.uppercase) {
                yupConfig = yupConfig.matches(/[A-Z]/);
            }
            if (field.lowercase) {
                yupConfig = yupConfig.matches(/[a-z]/);
            }
            if (field.yupExtraConfig) {
                yupConfig = field.yupExtraConfig;
            }
            response[field.name] = yupConfig;
        });
    }
    return Yup.object(response);
}

function arrangeJsonForm(data) {
    let response = [];
    if (data && Object.keys(data).length > 0) {
        const pathTypes = [Number, String, Boolean, undefined, null];
        Object.keys(data).forEach(path => {
            const pathType = data[path]?.constructor;
            if (pathTypes.includes(pathType)) {
                response.push({
                    variant: path === 'rfc' ? 'rfc' : path === 'curp' ? 'curp' : null,
                    formGroupAdjust: pathType === undefined ? 'hidden' : null,
                    value: pathType === undefined ? null : data[path],
                    type: pathType === Boolean ? 'checkbox' : 'text',
                    disabled: path === 'id' || path === 'slug',
                    onlyNumbers: pathType === Number,
                    label: path,
                    name: path,
                    colsGrid: {
                        xs: pathType === Boolean ? 6 : 12,
                        sm: pathType === Boolean ? 6 : 12,
                        md: pathType === Boolean ? 6 : 12,
                        lg: pathType === Boolean ? 6 : 12,
                        xl: pathType === Boolean ? 6 : 12,
                    }
                });
            } else if (pathType === Object) {
                const fields = arrangeJsonForm(data[path]).map(field => {
                    return {
                        ...field,
                        name: `${path}-${field.name}`,
                        label: `${path}-${field.label}`,
                    }
                });
                response = [...response, ...fields];
            } else if (pathType === Array) {
                data[path].forEach((obj, index) => {
                    const fields = arrangeJsonForm(obj).map(field => {
                        return {
                            ...field,
                            name: `${path}-${index}-${field.name}`,
                            label: `${path}-${index}-${field.label}`,
                        }
                    });
                    response = [...response, ...fields];
                });
            }
        });
    }
    return response;
}

function getJsonFormValue(values, data, prevPath) {
    let response = {};
    if (data && Object.keys(data).length > 0) {
        const pathTypes = [Number, String, Boolean, undefined, null];
        Object.keys(data).forEach(path => {
            const actualPath = prevPath ? `${prevPath}-${path}` : path;
            const pathType = data[path]?.constructor;
            if (pathTypes.includes(pathType)) {
                response[path] = pathType === undefined ? null : values[actualPath];
            } else if (pathType === Object) {
                response[path] = getJsonFormValue(values, data[path], actualPath);
            } else if (pathType === Array) {
                response[path] = [];
                data[path].forEach((obj, index) => {
                    response[path][index] = getJsonFormValue(values, obj, `${actualPath}-${index}`);
                });
            }
        });
    }
    return response;
}

function removeEmptyParams(obj) {
    return Object.fromEntries(
        Object.entries(obj)
            .filter(([_, v]) => {
                return v != undefined && v != null && v !== "undefined" && v !== ""
            })
            .map(([k, v]) => [k, (Array.isArray(v) || typeof (v) !== "object") ? v : removeEmptyParams(v)])
    );
}

function convertValueToSlug(value) {
    return latinise(value)
        .toLowerCase()
        .trim()
        .replace(/[^\w\s-]/g, '')
        .replace(/[\s--]+/g, '_')
        .replace(/^-+|-+$/g, '');
}

function latinise(str) {
    return str.replace(/[^A-Za-z0-9\[\] ]/g, function (a) {
        return getLatinise().latin_map[a] || a
    });
}

function isLatin(str) {
    return str == latinise();
}

function getProjectApiData() {
    const user_token = getCookie('nominex') || null;
    return {
        url: process.env.REACT_APP_GQL_URI,
        token: user_token
    };
}

async function executeGraphqlQueryAsFetch(apiData, params) {
    const {url, token} = apiData;
    const {queryBody} = params;
    let body = null;
    let dataResponse = null;
    let errorResponse = null;

    if (queryBody) body = JSON.stringify({query: queryBody});
    await fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        },
        body: body
    })
        .then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                console.log(res);
                throw new Error(res.statusText)
            }
        })
        .then(async (data) => {
            dataResponse = (data.data) ? data.data : data;
        })
        .catch(async (err) => {
            errorResponse = err.message;
        });
    return {
        data: dataResponse,
        error: errorResponse
    };
}

function isNumeric(str) {
    if (typeof str != "string") return false
    return !isNaN(str) &&
        !isNaN(parseFloat(str))
}

export {
    arrangeFormFields, arrangeFormikInitialValues, arrangeFormikSchema, arrangeJsonForm, convertValueToSlug, executeGraphqlQueryAsFetch, getJsonFormValue, getProjectApiData, getValue, isLatin, isNumeric, latinise, removeEmptyParams
};

