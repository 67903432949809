export const NOTIFICATION_CATEGORIES = {
    birthday:{
        slug:'CUMPLEANIOS',
        label:'Cumpleaños',
        imageName:'notificacion_categoria_cumpleanios',
        imageExtension:'png'
    },
    events:{
        slug:'EVENTOS',
        label:'Eventos',
        imageName:'notificacion_categoria_eventos',
        imageExtension:'png'
    },
    reminders:{
        slug:'RECORDATORIOS',
        label:'Recordatorios',
        imageName:'notificacion_categoria_recordatorios',
        imageExtension:'png'
    },
    actions:{
        slug:'ACCIONES',
        label:'Acciones',
        imageName:'notificacion_categoria_acciones',
        imageExtension:'png'
    },
    vacations:{
        slug:'SOLICITUDES_VACACIONES',
        label:'Solicitudes vacaciones',
        imageName:'notificacion_categoria_solicitudes_vacaciones',
        imageExtension:'png'
    },
    payrolls:{
        slug:'NOMINAS',
        label:'Nóminas',
        imageName:'notificacion_categoria_nominas',
        imageExtension:'png'
    },
    imports:{
        slug:'IMPORTACIONES',
        label:'Importaciones',
        imageName:'notificacion_categoria_importaciones',
        imageExtension:'png'
    }
};

export const NOTIFICATION_STATUSES = {
    progress:{
        slug:'PROCESO_EN_PROCESO',
        styleColor:'#105D96',
        label:'Pendiente',
        color:'primary',
    },
    success:{
        slug:'PROCESO_EXITOSO',
        styleColor:'#4BB543',
        label:'Exitoso',
        color:'success',
    },
    failed:{
        slug:'PROCESO_FALLIDO',
        styleColor:'#A50203',
        label:'Fallido',
        color:'danger',
    },
};
