import { gql, useLazyQuery, useMutation} from "@apollo/client";
import { useToasts } from "react-toast-notifications";
import { DEFAULT_GRAPHQL_ERROR_POLICY, DEFAULT_GRAPHQL_FETCH_POLICY, DEFAULT_GRAPHQL_MUTATION_ERROR_MESSAGE, DEFAULT_GRAPHQL_NEXT_FETCH_POLICY, DEFAULT_GRAPHQL_SUCCESS_MESSAGE } from "../configs/constants";

export const useNQuery = (gqlQuery, params = {}) => {
    const { addToast } = useToasts();
    const fetchPolicy = params.fetchPolicy || DEFAULT_GRAPHQL_FETCH_POLICY;
    const nextFetchPolicy = params.nextFetchPolicy || DEFAULT_GRAPHQL_NEXT_FETCH_POLICY;
    const errorPolicy = params.errorPolicy || DEFAULT_GRAPHQL_ERROR_POLICY;
    const pollInterval = params.pollInterval;
    const queryVariables = gqlQuery?.variables;
    const query = gqlQuery?.query || gql`{fake query{ok,error}}`;
    
    const [runQuery, { called, loading, data, error, networkStatus, refetch, variables }] = useLazyQuery(query, {
        ...(fetchPolicy ? { fetchPolicy } : null),
        ...(nextFetchPolicy ? { nextFetchPolicy } : null),
        ...(errorPolicy ? { errorPolicy } : null),
        ...(queryVariables ? { variables: queryVariables } : null),
        ...(pollInterval ? { pollInterval } : null),
        onError: (error) => {
            addToast(`Error desconocido: ${error.message}`, {appearance: "error"});
        }
    });
    return { runQuery, called, loading, data, error, networkStatus, refetch, variables };
}

export const useNMutation = (gqlMutation, params = {}) => {
    const { addToast } = useToasts();
    const errorPolicy = params.errorPolicy || DEFAULT_GRAPHQL_ERROR_POLICY;
    const mutationVariables = gqlMutation?.variables;
    const noUseGenericNotification = params.noUseGenericNotification || false;
    const mutation = gqlMutation?.mutation || gql`mutation {fake mutation { ok error } }`;

    const [runMutation, { called, loading, data, error, networkStatus, reset }] = useMutation(mutation, {
        ...(mutationVariables ? { variables: mutationVariables } : null),
        ...(errorPolicy ? { errorPolicy } : null),
        onError: (error) => {
            if (!noUseGenericNotification) {
                addToast(DEFAULT_GRAPHQL_MUTATION_ERROR_MESSAGE, {appearance: "error"});
            }
        },
        onCompleted: (data,errors) => {
            if (!noUseGenericNotification) {
                if (data) {
                    const response = Object.values(data)[0];
                    if (response.ok) {
                        addToast(DEFAULT_GRAPHQL_SUCCESS_MESSAGE, {appearance: "success"});
                    } else if (response.error) {
                        addToast(response.error, {appearance: "error"});
                    } else{
                        addToast(DEFAULT_GRAPHQL_MUTATION_ERROR_MESSAGE, {appearance: "error"});
                    }
                } else if (errors) {
                    this.onError(errors);
                }
            }
        }
    });
    return { runMutation, called, loading, data, error, networkStatus, reset };
}