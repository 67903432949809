import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const ConfirmationModalCallBack = ({
	handleModal,
	isOpen,
	title,
	children,
	noFotter,
}) => {
	return (
		<Modal centered isOpen={isOpen} toggle={() => handleModal("cancel")}>
			<ModalHeader toggle={() => handleModal("cancel")}>{title}</ModalHeader>
			<ModalBody>
				{children}
			</ModalBody>
			{!noFotter ? (
				<ModalFooter>
					<Button color="secondary" onClick={() => handleModal("cancel")}>
						Cancelar
					</Button>
					<Button
						color="primary"
						onClick={() => handleModal("accept")}
					>
						Aceptar
					</Button>
				</ModalFooter>
			):null}
		</Modal>
	);
};

export default ConfirmationModalCallBack;
