const initialState = {
  data: {
    role_slug: 'guest'
  }
};

export const session = (state = initialState, action) => {
  switch (action.type) {
    case "SET_SESSION": {
      return { ...state, data: action.payload };
    }
    case "UNSET_SESSION": {
      return { ...state, data: action.payload };
    }
    default:
      return state;
  }
};
