import React from "react";
import {
    AlertCircle,
    Bell,
    Calendar,
    Check,
    CheckCircle,
    ChevronDown,
    ChevronLeft,
    ChevronRight,
    ChevronsLeft,
    ChevronsRight,
    Clock,
    Copy,
    CreditCard,
    Download,
    Edit,
    Edit3,
    Eye,
    EyeOff,
    FilePlus,
    Home,
    Lock,
    Mail,
    MessageSquare,
    MinusCircle,
    MoreVertical,
    Paperclip,
    Plus,
    PlusCircle,
    RefreshCw,
    Save,
    Search,
    Send,
    Slash,
    Sunrise,
    Sunset,
    Trash,
    Upload,
    User,
    X,
    XCircle,
    DollarSign,
} from "react-feather";

const useIcon = (props) => {
    const {name,adjust,size} = props;
    let response = <Slash className={adjust} size={size} />;
    if (name) {
        switch(name){
            case "edit":
                response = <Edit className={adjust} size={size} />;
                break;
            case "trash":
                response = <Trash className={adjust} size={size} />;
                break;
            case "search":
                response = <Search className={adjust} size={size} />;
                break;
            case "plusCircle":
                response = <PlusCircle className={adjust} size={size} />
                break;
            case "eye":
                response = <Eye className={adjust} size={size} />
                break;
            case "eyeOff":
                response = <EyeOff className={adjust} size={size} />
                break;
            case "editPencil":
                response = <Edit3 className={adjust} size={size} />
                break;
            case "save":
                response = <Save className={adjust} size={size} />
                break;
            case "checkCircle":
                response = <CheckCircle className={adjust} size={size} />
                break;
            case "clock":
                response = <Clock className={adjust} size={size} />
                break;
            case "filePlus":
                response = <FilePlus className={adjust} size={size} />
                break;
            case "upload":
                response = <Upload className={adjust} size={size} />
                break;
            case "check":
                response = <Check className={adjust} size={size} />
                break;
            case "alertCircle":
                response = <AlertCircle className={adjust} size={size} />
                break;
            case "refreshCw":
                response = <RefreshCw className={adjust} size={size} />
                break;
            case "paperclip":
                response = <Paperclip className={adjust} size={size} />
                break;
            case "minusCircle":
                response = <MinusCircle className={adjust} size={size} />
                break;
            case "mail":
                response = <Mail className={adjust} size={size} />
                break;
            case "lock":
                response = <Lock className={adjust} size={size} />
                break;
            case "user":
                response = <User className={adjust} size={size} />
                break;
            case "download":
                response = <Download className={adjust} size={size} />
                break;
            case "x":
                response = <X className={adjust} size={size} />
                break;
            case "xCircle":
                response = <XCircle className={adjust} size={size} />
                break;
            case "send":
                response = <Send className={adjust} size={size} />
                break;
            case "messageSquare":
                response = <MessageSquare className={adjust} size={size} />
                break;
            case "chevronsRight":
                response = <ChevronsRight className={adjust} size={size} />
                break;
            case "chevronsLeft":
                response = <ChevronsLeft className={adjust} size={size} />
                break;
            case "chevronRight":
                response = <ChevronRight className={adjust} size={size} />
                break;
            case "chevronLeft":
                response = <ChevronLeft className={adjust} size={size} />
                break;
            case "chevronDown":
                response = <ChevronDown className={adjust} size={size} />
                break;
            case "plus":
                response = <Plus className={adjust} size={size} />
                break;
            case "copy":
                response = <Copy className={adjust} size={size} />
                break;
            case "bell":
                response = <Bell className={adjust} size={size} />
                break;
            case "home":
                response = <Home className={adjust} size={size} />
                break;
            case "calendar":
                response = <Calendar className={adjust} size={size} />
                break;
            case "moreVertical":
                response = <MoreVertical className={adjust} size={size} />
                break;
            case "creditCard":
                response = <CreditCard className={adjust} size={size} />
                break;
            case "sunrise":
                response = <Sunrise className={adjust} size={size} />
                break;
            case "sunset":
                response = <Sunset className={adjust} size={size} />
                break;
            case "dollar":
                response = <DollarSign className={adjust} size={size} />
                break;
        }
    }
    return response;
}
export default useIcon;
