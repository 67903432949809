import {NOTIFICATION_STATUSES} from '../../../components/nominex/Notifications/notificationUtils';
import {Alert, Badge, Button, Card, CardBody, Col, Row, Spinner} from 'reactstrap';
import {useNMutation, useNQuery} from '../../../services/decorators/apolloGraphQL';
import {DEFAULT_GRAPHQL_POLL_INTERVAL} from '../../../services/configs/constants';
import {useToasts} from 'react-toast-notifications';
import React, {useEffect, useState} from 'react';
import {gql} from '@apollo/client';

const NotificationView = (props = {}) => {
    //Consts&states
    const {id = null} = props;
    const {addToast} = useToasts();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [redirectTo, setRedirectTo] = useState('');
    const [notificationData, setNotificationData] = useState(null);

    //queries&mutations
    const GET_ENTITY = {
        query: gql`
            query{
                responseQuery: obtenerNotificacion(id:"${id}"){
                    id
                    link
                    leida
                    descripcion
                    estatusProceso
                }
            }
        `
    };

    const UPDATE_ENTITY = {
        mutation: gql`
            mutation actualizarNotificacion($id:String!,$input:ActualizarNotificacionInput!){
                responseMutation: actualizarNotificacion(id:$id,input:$input){
                    ok
                }
            }
        `
    };

    //handleFunctions
    const {
        runQuery: executeQueryEntity,
        data: dataEntity,
        loading: loadingEntity,
        error: errorEntity,
    } = useNQuery(GET_ENTITY);
    const {runMutation: updateEntity} = useNMutation(UPDATE_ENTITY);

    //effects
    useEffect(() => {
        if (id) {
            executeQueryEntity();
        }
    }, [id]);

    useEffect(() => {
        if (!loadingEntity
            && !errorEntity
            && dataEntity) {
            setLoading(false);
            setError(false);
            setNotificationData(arrangeNotificationData(dataEntity.responseQuery));
        } else if (errorEntity) {
            setLoading(false);
            setError(true);
        }
    }, [dataEntity, loadingEntity, errorEntity]);

    useEffect(() => {
        if (notificationData && notificationData.id && !notificationData.leida) {
            markAsRead({id});
        }
    }, [notificationData]);

    //arrangeData
    const arrangeNotificationData = (dataItem) => {
        let response = null;
        if (dataItem) {
            let estatusData;
            Object.keys(NOTIFICATION_STATUSES).forEach((subjectKey) => {
                if (NOTIFICATION_STATUSES[subjectKey].slug === dataItem?.estatusProceso) {
                    estatusData = NOTIFICATION_STATUSES[subjectKey];
                }
            });
            response = {...dataItem, estatusProceso: estatusData};
        }
        return response;
    }

    //handleFunctions
    const markAsRead = async (props = {}) => {
        const {id} = props;
        if (id) {
            let response = null;
            let errorResponse = false;
            try {
                response = await updateEntity({
                    variables: {
                        id: id,
                        input: {
                            leida: true
                        }
                    }
                });
                if (response?.data?.responseMutation?.ok) {
                    //OK
                } else {
                    errorResponse = true;
                }
            } catch (err) {
                console.log(err);
                errorResponse = true;
            }
            if (errorResponse) {
                addToast('Lo sentimos, hubo un error al intentar marcar como leída la notificación, favor de intentarlo más tarde o ponerse en contacto con el proveedor del sistema.', {appearance: 'error'});
            }
        }
    }

    const handleVisitLink = () => {
        setRedirectTo(notificationData.link);
    }

    //renders
    if (redirectTo) {
        window.location.href = redirectTo;
    }

    //Response
    return (
        <React.Fragment>
            <Row>
                <Col sm='12'>
                    {(loading) ? (
                        <Card>
                            <CardBody className='text-center'>
                                <Spinner color='primary' size='lg'/>
                            </CardBody>
                        </Card>
                    ) : error ? (
                        <Card>
                            <CardBody>
                                <Alert color='danger'>
                                    <div className='alert-heading'>Error</div>
                                    Lo sentimos, hubo un problema al intentar obtener la información de esta sección,
                                    favor de intentarlo más tarde o ponerse en contacto con el proveedor del sistema.
                                </Alert>
                            </CardBody>
                        </Card>
                    ) : (
                        <>
                            {notificationData?.estatusProceso &&
                                <NotificactionEstatus
                                    label={notificationData.estatusProceso.label}
                                    color={notificationData.estatusProceso.color}
                                />
                            }
                            <Card>
                                <CardBody>
                                    <span>{notificationData?.descripcion}</span>
                                </CardBody>
                            </Card>
                            {notificationData?.link &&
                                <div className='w-100 mb-1 text-center'>
                                    <Button color='primary' onClick={handleVisitLink}>
                                        Ver más...
                                    </Button>
                                </div>
                            }
                        </>
                    )}
                </Col>
            </Row>
        </React.Fragment>
    );
};

const NotificactionEstatus = (props) => {
    const {label, color} = props;
    return (
        <div className='w-100 my-1'>
            {label && (
                <div className='d-flex justify-content-center'>
                    <Badge pill color={color}>&nbsp;&nbsp;</Badge>
                    <span className='mx-1 my-auto'>Estatus {label.toLowerCase()}</span>
                </div>
            )}
        </div>
    )
}

export default NotificationView;
